import React, { useState } from 'react';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import {
  defaultSlugLocales,
  defaultCategoryLocales,
  locales,
  Layout,
  localize,
  getValidLocale,
  LocaleType,
  Product,
  GlobalConfig,
  FrontPageLocalized,
  Store,
  Footerv2Props,
} from '@lilbit/shared';
import {
  getSiteConfig,
  getProductForFrontPage,
  getWoocommerceProducts,
  getLildogProductPrices,
  getFooter,
  getTaxRate,
  getStores,
  getNewFrontPage,
  getReviews,
} from '../../lib/api';
import RenderSections from '../../components/RenderSections';
import { urlFor } from '../../lib/sanity';
import SimpleBlockContent from '../../components/SimpleBlockContent';
import Image from 'next/image';

const IndexPage: NextPage<{
  frontPage: FrontPageLocalized;
  config: GlobalConfig;
  mainProductPrices: any;
  products: Product[];
  mainProduct: Product;
  locale: LocaleType;
  footer: Footerv2Props;
  standardTaxRate?: number;
  productTaxRate?: number;
  redirect: boolean;
  categories: Array<{ urlPrefix: string; locale: string }>;
  stores: Store[];
  reviews: any;
}> = ({
  frontPage,
  config,
  mainProductPrices,
  products,
  mainProduct,
  locale,
  footer,
  standardTaxRate,
  productTaxRate,
  stores,
  reviews,
}) => {
  const [open, setopen] = useState(false);
  const setVippsModalOpen = () => {
    setopen(!open);
  };
  footer.SimpleBlockContent = SimpleBlockContent;

  return (
    <Layout
      config={config}
      metadata={frontPage.description || ''}
      user=""
      imageMetadata={
        frontPage.meta_image ? urlFor(frontPage.meta_image, true).url() : ''
      }
      setMarginX={false}
      urlFor={urlFor}
      siteName="lildog"
      title={`${frontPage.title ? frontPage.title : 'lildog'} | ${
        locale?.displayName
      }`}
      footer={footer}
      mainProduct={mainProduct}
    >
      {frontPage.content && (
        <>
          <RenderSections
            locale={locale}
            sections={frontPage.content}
            mainProduct={mainProduct}
            mainProductPrices={mainProductPrices}
            config={config}
            products={products}
            setVippsModalOpen={setVippsModalOpen}
            standardTaxRate={standardTaxRate}
            productTaxRate={productTaxRate}
            stores={stores}
            reviews={reviews}
          />
        </>
      )}
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const locale = getValidLocale(params.lang as string);
  const sanityLanguageIdentifier = locale?.sanityLanguageIdentifier;
  // const frontPages = await getFrontPage(sanityLanguageIdentifier);
  const frontPages = await getNewFrontPage(sanityLanguageIdentifier);
  const frontPage = frontPages[0];
  const localizedFrontPage = localize(frontPage, [sanityLanguageIdentifier]);
  let config = await getSiteConfig(sanityLanguageIdentifier);
  const footerData = await getFooter();
  const footer = localize(footerData, [sanityLanguageIdentifier]);
  let mainProductPrices = await getLildogProductPrices();
  let counter1 = 0;
  while (!mainProductPrices && counter1 < 10) {
    if (!mainProductPrices) {
      mainProductPrices = await getProductForFrontPage();
      counter1++;
    } else {
      break;
    }
  }
  const mainProduct = await getWoocommerceProducts(true);
  const stores = await getStores(sanityLanguageIdentifier);
  let products = await getWoocommerceProducts();
  let counter2 = 0;
  while (!products && counter2 < 10) {
    if (!products || products?.length <= 0) {
      products = await getWoocommerceProducts();
      counter2++;
    } else {
      break;
    }
  }

  const standardTaxRate = await getTaxRate('standard');
  const productTaxRate =
    mainProduct.tax_class === ''
      ? standardTaxRate
      : await getTaxRate(mainProduct.tax_class);
  if (config && defaultSlugLocales) {
    config.slugsForAllLocales = defaultSlugLocales;
  }
  if (config && defaultCategoryLocales)
    config.slugsForAllCategories = defaultCategoryLocales;
  const acceptedReviewLanguages = ['no', 'en'];
  const languageIdentifier: string = acceptedReviewLanguages.includes(
    sanityLanguageIdentifier
  )
    ? sanityLanguageIdentifier
    : 'en';
  const reviews = await getReviews(languageIdentifier);
  return {
    props: {
      frontPage: JSON.parse(JSON.stringify(localizedFrontPage)),
      config,
      mainProductPrices,
      mainProduct: mainProduct || null,
      products,
      locale,
      footer,
      standardTaxRate,
      productTaxRate,
      stores,
      reviews,
    },
    revalidate: 60 * 60,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: locales.map((l) => ({
      params: { lang: `${l.urlPrefix}` },
    })),
    fallback: 'blocking',
  };
};

const localizePaths = (slugs, locales) => {
  var localizedPaths = [];
  for (let locale of locales) {
    for (let slugContainer of slugs) {
      let category =
        slugContainer.slug[locale?.sanityLanguageIdentifier]?.current;
      localizedPaths.push({
        urlPrefix: category || null, // added || null to avoid undefined. Should maybe be removed when we get all the languages in sanity
        locale: locale?.sanityLanguageIdentifier,
      });
    }
  }
  return localizedPaths;
};

export default IndexPage;
